.top-banner {
  position: relative;
  width: 100%;
  height: 100vh; /* Set height to 100vh for fullscreen */
}

.video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%; /* 16:9 aspect ratio (9 / 16 * 100) */
}

.banner-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 15%; /* Adjust this value for the desired left alignment */
  transform: translateY(-50%);
  color: #e7ddd9;
  text-align: left;
}

.text-overlay h1 {
  font-size: 50px;
  color: #e7ddd9;
  letter-spacing: .1em;
  margin-bottom: 0.5em;
  width: 60%;
  font-family: Centaur;
}

.text-overlay p {
  font-size: 1.2em;
}

.fade-in-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.learn-more {
  background-color: #e7ddd9;
  color: #808154;
  font-family:  'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
}

.sub-text {
font-family:  'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
text-transform: uppercase;
font-size: 25px !important;
font-weight: 600 !important;;
padding-bottom: 1%;
}

