/* NavBar.css */
.header-container {
  background-color: #808154;
  border: 1px solid #1a1a1a;
  padding: 2% 10%;
  position: sticky !important;
  top: 0;
  z-index: 90000;
}


.logo img {
  max-width: 200px; /* Adjust the size of the logo as needed */
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu {
  display: flex;
  align-items: center;
}

.hamburger {
  display: none;
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 6px 0;
}

.navbar-items {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.nav-item {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 20px;
  color: #e2dce5;
  text-decoration: none;
  margin-right: 40px; /* Increase margin-right to create more space between menu items */
  padding: 10px; /* Add padding to increase the clickable area of menu items */
}

.nav-item:hover {
  color: white; /* Change color on hover */
}

.contact-container {
  display: flex;
  align-items: center;
}

button {
  background-color: white;
  color: #1a1a1a;
  padding: 10px 20px;
  font-size: 20px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .menu {
    display: none;
  }

  .hamburger {
    display: block;
  }

  .navbar-items {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    background-color: #282c34;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
  }

  .navbar-items.open {
    transform: translateX(0);
  }

  .contact-container {
    display: none;
  }
}

.callNow {
  background-color: white;
  color: #808154;
  text-decoration: none;
}





button {
  background-color: white;
  color: #1a1a1a;
  padding: 10px 20px;
  font-size: 20px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
}