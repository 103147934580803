.SEOBanner {
    margin-top: 5%;
    background-color: #d9a2a3;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4.75% 12%;
}

.seo-text {
    flex: 1;
    padding-right: 15%; /* Add some spacing between text and image */
}

.image {
    flex: 1;
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.5s ease, transform 0.5s ease, filter 0.5s ease;
}

.image.visible {
    opacity: 1;
    transform: translateY(0);
    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1));
}

.image img {
    max-width: 100%; /* Ensure the image doesn't exceed its container's width */
}

.seo-heading {
    color: #e7ddd9;
    border-bottom: 5px solid #808154;
    margin-right: 93.5%;
    padding-bottom: 1%;
    font-family: Centaur;
    font-weight: 600;
}

.seoButton {
    background-color: #e7ddd9;
    color: #808154;
}

.seo-banner-text {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 20px;
    color: #202020;
}

@media (max-width: 768px) {
    .SEOBanner {
        padding: 10%;
        flex-direction: column-reverse; /* Reverse the order of elements for mobile view */
    }

    .seo-text {
        padding-right: 0;
        text-align: center;
    }

    .image {
        width: 100%; /* Adjust image width for mobile view */
    }
    .seo-heading {
        margin-right: 0; /* Remove right margin for header text in mobile view */
    }
}
