.about-text {
    display: flex;
    justify-content: center; /* Center horizontally */
    text-align: center; /* Center the text within the container */
    background-color: #e7ddd9;
    padding: 6% 3%;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 30px;
    border-bottom: 1px solid black;
  }
  
  .about-text p {
    margin: 0; /* Remove default margin on the paragraph element */
  }
  
  .book-now-button {
    background-color: #808154;
    color: white;
    padding: 1% 2%;
    cursor: pointer;
    font-size: 20px;
    margin-top: 20px; /* Adjust this to add space between the text and the button */
  }

  @media screen and (max-width: 767px) {
    .about-text {
      padding: 12% 6%;
    }
    .book-now-button {
      padding: 3% 6%;
    }
  }

  #contact {
    color: white !important;
    text-decoration: none;
  }

  .book-button {
    color: white !important;
    text-decoration: none;
  }

  .smaller-about {
font-size: 25px;
font-family: Centaur;
font-weight: 600;
  }

  .centered-content {
    /* Initial state */
    transform: translateY(100%);
    opacity: 0;
    transition: transform 0.5s ease-out, opacity 0.5s ease-out;
  }
  
  .centered-content-show {
    /* Transition to visible state */
    transform: translateY(0);
    opacity: 1;
  }
  