.App {
  text-align: center;
  overflow-x: hidden !important;
}

body {
background-color: #e7ddd9;
margin: 0;
padding: 0;
overflow-x: hidden !important;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.space {
  padding-top: 1.5%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App {
  text-align: center;
}

.top-banner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.mobile-image {
  width: 100%;
  height: auto;
  display: block;
}

.desktop-image {
  display: none; /* Initially hide the desktop image */
}

@media (min-width: 768px) {
  .mobile-image {
    display: none; /* Hide the mobile image on larger screens */
  }

  .desktop-image {
    display: block; /* Show the desktop image on larger screens */
    width: 100%;
    height: auto;
  }
}
.banner-image {
  width: 100%;
  height: auto;
  display: block;
}

.about-section {
  background-color: white;
  color: #1a1a1a;
  padding: 20px;
}

.about-content {
  max-width: 800px;
  margin: 0 auto;
}

.empty-div {
padding-bottom: 5%;
background-color: #e7ddd9;
}