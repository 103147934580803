.video-with-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 9999;
  background-color: #e7ddd9;
}

.video-container {
  flex: 1;
}

.video-container-f1 video {
  padding: 5% 33%;
  width: 60%;
  height: auto;
}

.text-container {
  flex: 1;
  padding: 0 20px;
  text-align: left;
  padding-right: 20%;
}

.upper-text {
  font-family: Centaur;
  font-size: 30px;
  font-weight: 600;
}

.middle-text,
.lower-text {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 20px;
}

@media (max-width: 768px) {
  .video-with-text {
    flex-direction: column;
    align-items: center;
  }

  .video-container {
    order: 2; /* Change order to display below text in mobile */
    padding: 0;
  }

  .text-container {
    order: 1; /* Change order to display above video in mobile */
    padding: 20px 20px; /* Adjust top padding to create space between text and video */
  }
}


@media (max-width: 768px) {
  .video-container {
    padding: 0; /* Remove padding in mobile view */
  }
  .text-container {
    text-align: center;
    padding-bottom: 10% !important;
  }
  
}

@media (max-width: 768px) {
  .video-container-f1 video {
    padding: 0;
    width: 60%;
    height: auto;
  }
  .upper-text {
    margin: 0 !important;;
  }
  .video-with-text {
    padding-top: 5%;
  }
}