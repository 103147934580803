/* ContactForm.css */
.contact-form {
    max-width: 75%;
    margin: 0 auto;
    padding: 20px;
    color: #808154;
    padding-bottom: 8%;
  }
  
  .form-row {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
    justify-content: space-between;
  }
  
  .form-group {
    width: 48%;
    display: flex;
    flex-direction: column;
    color: #808154;
  }
  
  .message-group {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .form-group label,
  .message-group label {
    color: #808154;
    font-size: 18px;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group textarea,
  .message-group textarea {
    border: 1px solid #808154;
    padding: 5px;
    background: white;
    color: #808154;
    width: 100%; /* Set the width to 100% for message input */
  }
  
  .message-group textarea.full-width {
    width: 100%; /* Set the width to 100% for message input */
  }
  
  .form-group button {
    background: #808154;
    color: #1a1a1a;
    border: none;
    padding: 10px;
    cursor: pointer;

  }
  
  .contact-title {
font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
font-weight: 400;
font-size: 35px;
text-align: center;
color: #808154;
  }

  .button-group {
width: 100%;
margin-top: 2%;
  }

  button.contact-button {
      width: 100%;
      background-color: #808154;
      color: white;
  }

  label {
    text-align: left;
  }

  #contact {
    padding-bottom: 100px;
  }